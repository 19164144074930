import React from 'react'
import { useEffect } from 'react'
import Layout from '../../../components/Layout'
import Seo from '../../../components/Seo'
import i18n from '../../../locales/he.yaml'
import { fireEvent } from '../../../analytics'

const WebinarThanksTemplate = () => {
  useEffect(() => {
    fireEvent('SubmitApplication')
  }, [])
  return (
    <div className='hero has-navbar-fixed-top'>
      <div className='hero-body'>
        <div className='container content'>
          {/* <p>אימייל עם גישה להדרכה ישלח אלייך בדקות הקרובות.</p> */}
          <p>{i18n.webinarThanks.text1}</p>
          <h2>{i18n.webinarThanks.header}</h2>
          <p>{i18n.webinarThanks.text2}</p>
          <p className='is-flex justify-center'>
            <a
              href={i18n.webinarThanks.callToActionLink}
              className='button is-nude is-flex items-center justify-start'
              target='_blank'
              rel='noreferrer'
            >
              {i18n.webinarThanks.callToAction}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

const WebinarThanks = ({ location: { pathname } }) => {
  return (
    <Layout>
      <Seo
        metadata={i18n.webinarThanks.metadata}
        pathname={pathname}
        robots='noindex, nofollow'
      />
      <WebinarThanksTemplate />
    </Layout>
  )
}

export default WebinarThanks
